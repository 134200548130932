import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

import './nav.scss';

export const Nav = () => {

    const { auth } = useSelector(state => state.auth);
    return(
        <nav className='menu'>
            <div className="container">
                <div className="menu__wrapper">
                    <input type="checkbox" id="menu" className="menu__open"/>
                    <Link className="menu__logo" to={`/`}> 
                        <svg width="100" height="50" viewBox="0 0 100 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36.2656 0.246801C35.1512 0.635559 34.269 1.57506 32.7212 4.00479C28.9136 9.99814 26.855 11.8285 23.9452 11.8123C21.5925 11.7961 19.8745 10.9538 17.723 8.71848C16.9027 7.87617 15.974 6.75849 15.649 6.24015C15.3239 5.738 15.0453 5.33305 15.0144 5.36544C14.906 5.47883 15.4323 7.42262 15.8502 8.5241C17.1503 11.8285 19.3637 13.9829 22.3045 14.7928C23.682 15.1816 26.4681 15.133 28.0314 14.7118C29.5792 14.3069 31.1734 13.497 32.3342 12.5089C32.8295 12.1039 34.0059 10.8404 34.9345 9.70657C36.8848 7.39022 37.6122 6.74229 38.6957 6.35354C40.2899 5.7866 41.7294 6.33734 45.9858 9.12343C51.6662 12.849 54.2665 14.2421 58.9564 16.0725C64.9309 18.405 76.787 20.7538 83.7521 20.9805C86.3679 21.0615 88.9217 20.9643 88.736 20.77C88.705 20.7376 87.0489 20.4136 85.0522 20.0734C74.651 18.243 65.7202 15.6189 60.4422 12.8328C58.3837 11.7637 56.2941 10.4031 51.3257 6.93667C46.0477 3.25967 44.6702 2.36877 42.6271 1.34828C40.0268 0.0686207 37.8754 -0.303938 36.2656 0.246801Z" fill="black"/>
                            <path d="M13.4781 5.43025C12.3482 8.81568 11.5588 12.1363 11.2183 14.7928C10.9706 16.8014 11.1099 20.122 11.5279 21.7581C12.6577 26.2288 15.7224 29.922 20.2884 32.3355C24.0031 34.2793 30.2098 35.6723 38.9394 36.5147C42.6076 36.871 53.241 36.9358 56.5069 36.6118C64.4471 35.8343 74.1672 33.7934 80.5596 31.558C84.7696 30.1002 86.3639 29.7114 88.3141 29.6952C89.769 29.6952 90.0167 29.7438 90.6203 30.1002C92.8646 31.4284 91.7502 33.761 87.9891 35.6723C82.1848 38.6042 69.6631 41.3417 60.299 41.7143C57.1569 41.8277 57.1724 41.9573 60.4847 42.6376C65.5615 43.7067 72.0932 43.6581 78.8106 42.4918C86.0853 41.2445 91.5645 38.7986 94.9851 35.2836C98.1581 32.0115 98.7772 28.6585 96.6722 26.1478C95.6197 24.9005 93.4837 23.8962 91.4871 23.7019C89.2737 23.4751 84.4601 24.5442 76.783 26.9739C66.0722 30.3431 60.4383 31.3312 51.0896 31.5094C35.7045 31.7848 24.1424 28.9339 18.6322 23.4751C16.3879 21.2721 14.7163 18.2431 13.9115 14.9548C13.509 13.2864 13.4781 12.9624 13.4781 9.85237C13.4781 7.7304 13.5555 6.20776 13.6793 5.65702C13.9269 4.53934 13.8186 4.42596 13.4781 5.43025Z" fill="#A51111"/>
                            <path d="M42.9791 11.5694C42.3136 12.7518 39.868 15.3921 38.1655 16.8176C35.3949 19.1177 31.0611 21.6609 27.6404 22.9729C26.6653 23.3455 25.7212 23.7018 25.5509 23.7666C25.0092 23.9934 26.7891 24.3336 28.4917 24.3336C31.0765 24.3498 33.4602 23.6856 36.3081 22.1468C39.7906 20.284 41.9266 17.8219 43.0565 14.3393C43.3815 13.3512 43.753 10.6461 43.5673 10.6623C43.5363 10.6623 43.2732 11.0834 42.9791 11.5694Z" fill="black"/>
                            <path d="M49.4334 16.0077C47.7309 19.6361 42.716 24.4146 37.9178 26.9577L36.726 27.5894L37.8714 27.638C42.4838 27.8486 48.0095 23.9448 49.5418 19.4255C49.8668 18.4698 50.1145 16.3155 49.9752 15.6675L49.8513 15.1168L49.4334 16.0077Z" fill="black"/>
                            <path d="M56.1354 18.5184C55.0365 21.9201 51.3527 26.4394 47.3284 29.2903C46.7867 29.6628 46.3843 30.0192 46.4307 30.0516C46.57 30.2136 48.7834 29.7438 49.6501 29.3874C51.2289 28.7233 52.2814 27.962 53.7363 26.4556C54.8507 25.2893 55.2532 24.7547 55.6865 23.7829C56.5224 21.9525 56.77 20.284 56.445 18.5184L56.3366 17.8705L56.1354 18.5184Z" fill="black"/>
                            <path d="M62.1718 20.8834C62.0944 23.1673 60.9181 26.2774 59.1691 28.8367L58.3178 30.0677L58.8131 29.9058C60.2835 29.3712 61.8158 27.8 62.6826 25.8886C63.1005 24.9491 63.1624 24.6737 63.1624 23.3779C63.1624 22.1306 63.1005 21.7905 62.7445 20.9805C62.5123 20.4784 62.2957 20.0572 62.2647 20.0572C62.2337 20.0572 62.1873 20.4298 62.1718 20.8834Z" fill="black"/>
                            <path d="M9.79437 23.0215C8.9276 24.7709 6.29634 27.4922 3.49483 29.5332C1.94703 30.6833 1.23504 31.5418 0.910007 32.6757C0.12063 35.5104 2.58163 38.2803 7.85962 40.4994C13.7567 42.9777 20.629 44.0468 30.5813 44.0468C36.6177 44.063 41.1218 43.6095 45.208 42.5728C46.632 42.2164 49.6966 41.2283 49.8823 41.0501C49.8978 41.0501 46.1367 41.034 41.5242 41.034C31.2314 41.0178 26.2165 40.71 20.0563 39.7219C11.0481 38.2641 5.97131 35.9153 5.97131 33.194C5.97131 32.1897 6.188 31.801 7.78223 30.0192C9.77889 27.7676 10.7695 25.8724 10.7695 24.3174C10.7695 23.7018 10.4754 22.3574 10.3206 22.2602C10.2587 22.2116 10.0265 22.568 9.79437 23.0215Z" fill="black"/>
                            <path d="M45.2853 45.0187C29.9467 45.5695 17.2238 46.8167 5.11999 48.9711C-1.36528 50.1212 -1.44267 50.1698 3.4948 49.781C15.1342 48.8739 33.4756 48.0154 45.5949 47.8372C52.1885 47.7238 69.3072 47.9344 80.6525 48.2422C89.2583 48.4851 96.8735 48.7119 98.5296 48.7929L100 48.8739L98.1426 48.4689C90.5275 46.8491 78.3773 45.4723 67.7284 45.0025C63.5648 44.8243 50.6717 44.8243 45.2853 45.0187Z" fill="black"/>
                        </svg>
                    </Link>

                    <label htmlFor="menu">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_401_26)">
                        <path d="M4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7Z" fill="black"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_401_26">
                        <rect width="24" height="24" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </label>
                    <ul className="menu__list">
                        <li className="menu__item">
                            <Link className="menu__link" to={`/`}>Головна</Link>
                        </li>
                        <li className="menu__item">
                            <Link className="menu__link" to={`/shop`}>Магазин</Link>
                        </li>
                        <li className="menu__item">
                            <Link className="menu__link" to={`/about-us`}>Про нас</Link>
                        </li>
                    </ul>
                    <ul className="menu__user">
                        <li className="menu__item">
                            <Link className="menu__link menu__link_block" to={!auth.data ? "/auth" : auth.data.role==='client' ? "/account/client" : "/account/new-product"}>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_10_39)">
                                    <path d="M15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM8.8375 22.85C9.375 21.725 12.65 20.625 15 20.625C17.35 20.625 20.6375 21.725 21.1625 22.85C19.4625 24.2 17.325 25 15 25C12.675 25 10.5375 24.2 8.8375 22.85ZM22.95 21.0375C21.1625 18.8625 16.825 18.125 15 18.125C13.175 18.125 8.8375 18.8625 7.05 21.0375C5.775 19.3625 5 17.275 5 15C5 9.4875 9.4875 5 15 5C20.5125 5 25 9.4875 25 15C25 17.275 24.225 19.3625 22.95 21.0375ZM15 7.5C12.575 7.5 10.625 9.45 10.625 11.875C10.625 14.3 12.575 16.25 15 16.25C17.425 16.25 19.375 14.3 19.375 11.875C19.375 9.45 17.425 7.5 15 7.5ZM15 13.75C13.9625 13.75 13.125 12.9125 13.125 11.875C13.125 10.8375 13.9625 10 15 10C16.0375 10 16.875 10.8375 16.875 11.875C16.875 12.9125 16.0375 13.75 15 13.75Z" fill="black"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_10_39">
                                    <rect width="30" height="30" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                                <span className="menu__text">Аккаунт</span>
                            </Link>
                        </li>
                        <li className="menu__item">
                            <Link className="menu__link menu__link_block" to="/favourite">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_41_469)">
                                    <path d="M20.625 3.75C18.45 3.75 16.3625 4.7625 15 6.3625C13.6375 4.7625 11.55 3.75 9.375 3.75C5.525 3.75 2.5 6.775 2.5 10.625C2.5 15.35 6.75 19.2 13.1875 25.05L15 26.6875L16.8125 25.0375C23.25 19.2 27.5 15.35 27.5 10.625C27.5 6.775 24.475 3.75 20.625 3.75ZM15.125 23.1875L15 23.3125L14.875 23.1875C8.925 17.8 5 14.2375 5 10.625C5 8.125 6.875 6.25 9.375 6.25C11.3 6.25 13.175 7.4875 13.8375 9.2H16.175C16.825 7.4875 18.7 6.25 20.625 6.25C23.125 6.25 25 8.125 25 10.625C25 14.2375 21.075 17.8 15.125 23.1875Z" fill="black"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_41_469">
                                    <rect width="30" height="30" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                                <span className="menu__text">Вподобані</span>
                            </Link>
                        </li>
                        <li className="menu__item">
                            <Link className="menu__link menu__link_block" to="/basket">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_10_35)">
                                    <path d="M22.5 7.5H20C20 4.7375 17.7625 2.5 15 2.5C12.2375 2.5 10 4.7375 10 7.5H7.5C6.125 7.5 5 8.625 5 10V25C5 26.375 6.125 27.5 7.5 27.5H22.5C23.875 27.5 25 26.375 25 25V10C25 8.625 23.875 7.5 22.5 7.5ZM15 5C16.375 5 17.5 6.125 17.5 7.5H12.5C12.5 6.125 13.625 5 15 5ZM22.5 25H7.5V10H10V12.5C10 13.1875 10.5625 13.75 11.25 13.75C11.9375 13.75 12.5 13.1875 12.5 12.5V10H17.5V12.5C17.5 13.1875 18.0625 13.75 18.75 13.75C19.4375 13.75 20 13.1875 20 12.5V10H22.5V25Z" fill="black"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_10_35">
                                    <rect width="30" height="30" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                                <span className="menu__text">Корзина</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
};