import React from "react";

import './spiner.scss';

export const Spiner = () => {
    return(
        <>
            <div className="spin-wrapper">
                <div className="spinner">
                </div>
            </div>
        </>
    );
}